import getData from "@/__main__/get-data.mjs";
import { getRitoLanguageCodeFromBCP47 } from "@/app/util.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import LolArenaAugments from "@/game-lol/models/lol-arena-augments.mjs";
import LolArenaAugmentsStats from "@/game-lol/models/lol-arena-augments-stats.mjs";
import LolArenaStatsPatch from "@/game-lol/models/lol-arena-stats-patch.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";

export default async function fetchData() {
  const { patch } = await getData(
    API.getArenaStatsPatch(),
    LolArenaStatsPatch,
    ["lol", "arenaStatsPatch"],
  );

  const lang = getRitoLanguageCodeFromBCP47(getLocale());

  return Promise.all([
    getData(API.getArenaAugments(patch, lang), LolArenaAugments, [
      "lol",
      "arenaAugments",
      patch,
      lang,
    ]),
    getData(API.getArenaAugmentsStats(patch), LolArenaAugmentsStats, [
      "lol",
      "arenaAugmentsStats",
      patch,
    ]),
  ]);
}
